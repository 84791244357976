import { allPaths, postApi } from 'api/fetchApi'
import { definitions } from 'api/generated/masked_replies'

export const sendAnnounceAnswerFormApi = (adId: number, text: string) => {
  return postApi<
    definitions['PostReplyResponse'],
    definitions['MaskedReplyPostBodyRequest']
  >(`/ad/${adId}/reply` as allPaths, {
    adId,
    text,
    isChatEnabled: false,
  })
}

export const createPaidReplyOrder = (adId: number, replyId: number) => {
  return postApi<
    definitions['CreatePaidReplyOrderResponse'],
    definitions['MaskedPaidReplyOrderPostBodyRequest']
  >(`/ad/${adId}/reply/${replyId}/paid-order` as allPaths, {})
}
