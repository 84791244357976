import {
  AppDispatch,
  AsyncAction,
  AsyncThunkAction,
  AsyncThunkAction3,
} from 'actions/actions.types'
import { FieldName, FieldValueTypes } from 'actions/announce/announceForm.types'
import { NoticePaths } from 'components/page/notice/components.notice.paths'
import { Paths } from 'components/components.paths'
import { SUBMIT_ANSWER } from 'actions/captchaInlineActions'
import { definitions } from 'api/generated/masked_replies'
import { mergeAllUrls } from 'functions/mergeAllUrls'

import { captureException } from '@sentry/browser'
import {
  createPaidReplyOrder,
  sendAnnounceAnswerFormApi,
} from 'api/announce/sendAnnounceAnswerFormApi'
import { replace } from 'actions/system/router'

export const UPDATE_ANNOUNCE_ANSWER = 'UPDATE_ANNOUNCE_ANSWER' as const

export interface UpdateAnnounceAnswerAction {
  type: typeof UPDATE_ANNOUNCE_ANSWER
  name: string
  value: FieldValueTypes
}

export const updateAnnounceAnswerAction = (
  name: FieldName,
  value: FieldValueTypes
): UpdateAnnounceAnswerAction => ({
  name,
  value,
  type: UPDATE_ANNOUNCE_ANSWER,
})

export interface AnnounceAnswerFormAction
  extends AsyncAction<definitions['PostReplyResponse']> {
  type: typeof SUBMIT_ANSWER
}

const sendAnswerAnnouncePlainAction =
  (
    id: number,
    answerText: string
  ): AsyncThunkAction3<ReturnType<typeof sendAnnounceAnswerFormApi>> =>
  (dispatch) => {
    return dispatch({
      type: SUBMIT_ANSWER,
      promise: () => sendAnnounceAnswerFormApi(id, answerText),
    })
  }

export const sendAnnounceAnswerAction =
  (
    id: number
  ): AsyncThunkAction3<Promise<ReturnType<typeof sendAnnounceAnswerFormApi>>> =>
  async (dispatch, getState) => {
    const appDispatch = dispatch as AppDispatch
    const {
      announceAnswerForm: { answerText },
    } = getState()

    const response = await appDispatch(
      sendAnswerAnnouncePlainAction(id, answerText)
    )

    return response
  }

export const announceAnswerResponseAction =
  (
    locale: string,
    announcePostAnswer: definitions['PostReplyResponse']
  ): AsyncThunkAction<unknown> =>
  async (dispatch, getState) => {
    const {
      announceAnswerForm: { announceId },
    } = getState()

    if (announcePostAnswer.shouldBePaid && announceId) {
      try {
        const response = await createPaidReplyOrder(
          announceId,
          announcePostAnswer.id
        )
        if (!response.result?.orderId) {
          captureException(new Error('Order ID is not present'))
          throw new Error('Order ID is not present')
        }

        dispatch(
          replace(
            mergeAllUrls(
              locale,
              Paths.showcasePremiumPart,
              String(response.result.orderId)
            )
          )
        )
      } catch (error) {
        dispatch(replace(Paths.paymentUnavailable))
        captureException(new Error(`Payment unavailable - ${error}`))
      }
    } else {
      dispatch(replace(mergeAllUrls(locale, NoticePaths.sendAnswer)))
    }
  }

export const UPDATE_ANNOUNCE_ID = 'UPDATE_ANNOUNCE_ID' as const

export const updateAnnounceAnswerIdAction = (announceId: number) => ({
  type: UPDATE_ANNOUNCE_ID,
  announceId,
})

export type AnnounceAnswerTypes = AnnounceAnswerFormAction
